import { VisuallyHidden, Container, ChakraProvider, Box, Text } from '@chakra-ui/react';

function About() {
    return (
        <>
                <Box
          color="gray.500"
          fontWeight="semibold"
          letterSpacing="wide"
          ml="2"
          pt={2}
          >
            <Text>Welcome to the Wigboard</Text><br/>
            <Text>We are interested in knowing what people think about different topics.</Text>
            <Text>So what do we do? Well, we actually go out on the streets and ask...but in an unusual manner.
                <br/> One thing that is important to us is to explore different ways of communication.</Text>
                <br/>
            <Text>Where does the Wigboard name come from? We call ourselves 'wiggers', and if you see one of us you'll understand. If you don't, try to find us: we are identifiable with a wig and a board.</Text>
            <br/>
            <Text>We would love to hear back from you about your thoughts!</Text>
       </Box>
      </>
    );
}

export default About;
