import { Box, Text, Card, CardHeader, CardBody, CardFooter } from '@chakra-ui/react'

function SponsorCard(props) {

  if (props.content==null) 
  return (
    <>
      <Box
        w="100%"
        minHeight="150px"
        borderWidth="2px"
        borderRadius="lg"
        overflow="hidden"
        bg="gray.700"
        color="yellow"
        p={5}
        centercontent
      >
        <Text fontSize='4xl'>Sponsor a project!</Text>
        <Text fontSize='2xl'>Contact us today</Text>
      </Box>

    </>
  );

  return (
    <>
    <Box
    w="100%"
    minHeight="150px"
    borderWidth="2px"
    borderRadius="lg"
    overflow="hidden"
    bg="gray.700"
    color="yellow"
    p={5}
    centercontent
  >
    <a href={props.content.url}>
    <Text fontSize='4xl'>{props.content.bannerText1}</Text>
    <Text fontSize='2xl'>{props.content.bannerText2}</Text>
    </a>
  </Box>
  </>
  );
};

export default SponsorCard;