import { Box, VStack, Heading, StackDivider, CardHeader, CardBody, CardFooter } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react';
import axios from 'axios';

function Widgets() {
  return (
    <>
      <Box
        w="100%"
        h="100%"
        borderWidth="2px"
        borderRadius="lg"
        overflow="hidden"
        alignItems='center'
        p={2}
      >
        <Heading as='h5' size='sm'>Auckland Now!</Heading>
        <VStack
          divider={<StackDivider borderColor='gray.200' />}
          spacing={4}
          align='stretch'
        >
          <Box>
            <iframe id="widget-iframe" width="100%" height="203px"
              src="https://services.metservice.com/weather-widget/widget?params=white|small|portrait|days-1|classic&loc=auckland&type=urban"></iframe>
          </Box>

        </VStack>
      </Box>
    </>

  );
};

export default Widgets;