import React, { useEffect, useState } from 'react';
import { VisuallyHidden, Container, ChakraProvider, Box, theme } from '@chakra-ui/react';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { Grid, GridItem } from '@chakra-ui/react'
import { Image, Heading } from '@chakra-ui/react'

import bgHeader from './resources/wigboardV0.png'
import bgHeaderMobile from './resources/wigboardV0_600.png'
import CardContainer from './CardContainer';
import ProjectCard from './ProjectCard';
import SponsorCard from './SponsorCard';
import ContactForm from './ContactForm';
import About from './About';
import Widgets from './Widgets';
import axios from 'axios';
import baseURL from './Global';

const current = new Date();
const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
const webconfURL = baseURL + "webconf.php";

function App() {
  const [project, setProject] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [bannerContent, setBannerContent] = useState(null);
  const breakpoint = 600;

  useEffect(() => {
    axios.get(webconfURL).then((response) => {
      setProject(response.data[0]);
      setBannerContent(response.data[1]);
    });
  }, []);

  useEffect(() => {
   const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  // Mobile friendly version
   if (width < breakpoint) {
     return (
      <ChakraProvider theme={theme}>
      <Container maxW='{width}' bg='gray.200' centerContent>
        <Box w="105%" maxW='{width}' p={0} h="150px">
          <Image object-fit="contain" position="center" src={bgHeaderMobile} h="150px" alt="the Wigboard" />
          <VisuallyHidden>the Wigboard</VisuallyHidden>
        </Box>
        <Box>
        <Heading pt={1} as='h5' size='xs'>{date}</Heading>
        </Box>
        <Tabs pl={0} bg='white' w="105%" maxW='{width}' defaultIndex={0} variant="line" colorScheme="green" borderWidth="2px">
          <TabList>
            <Tab borderWidth="2px"><Heading pt={1} as='h5' size='sm'>The Board</Heading></Tab>
            <Tab borderWidth="2px"><Heading pt={1} as='h5' size='sm'>About...</Heading></Tab>
            <Tab borderWidth="2px"><Heading pt={1} as='h5' size='sm'>Contact</Heading></Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Grid

                templateRows="repeat(4, 1fr)"
                templateColumns="repeat(4, 1fr)"
                gap={1}
                fontSize={12}
              >
                <GridItem colSpan={1} rowSpan={3} bg="white">
                  <ProjectCard setProject={setProject} />
                </GridItem>
                <GridItem colSpan={3} rowSpan={3} bg="white">
                  <CardContainer project={project} />
                </GridItem>
                <GridItem colSpan={4} rowSpan={1} bg="white">
                  <SponsorCard content={bannerContent} />
                </GridItem>
              </Grid>
            </TabPanel>
            <TabPanel>
            <Box
                w="100%"
                h="100%"
                borderWidth="2px"
                borderRadius="lg"
                overflow="none"
              >
   
              <About />
              </Box>
            </TabPanel>
            <TabPanel>
              <Box
                w="100%"
                h="100%"
                borderWidth="2px"
                borderRadius="lg"
                overflow="none"
              >
                <ContactForm />
              </Box>

            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
    </ChakraProvider>
     );
   }

// Desktop version
  return (
    <ChakraProvider theme={theme}>
      <Container maxW='1000px' bg='gray.200' centerContent>
        <Box maxW="1000px" p={0} h="150px">
          <Image src={bgHeader} maxW="1000px" h="150px" alt="the Wigboard" />
          <VisuallyHidden>the Wigboard</VisuallyHidden>
        </Box>
        <Box>
        <Heading pt={1} as='h5' size='sm'>{date}</Heading>
        </Box>
        <Tabs pl={0} bg='white' w="110%" maxW="1000px" defaultIndex={0} variant="line" colorScheme="green" borderWidth="2px">
          <TabList>
            <Tab borderWidth="2px"><Heading pt={1} as='h5' size='sm'>The Board</Heading></Tab>
            <Tab borderWidth="2px"><Heading pt={1} as='h5' size='sm'>About...</Heading></Tab>
            <Tab borderWidth="2px"><Heading pt={1} as='h5' size='sm'>Contact</Heading></Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Grid

                templateRows="repeat(4, 1fr)"
                templateColumns="repeat(5, 1fr)"
                gap={1}
                fontSize={12}
              >
                <GridItem colSpan={1} rowSpan={3} bg="white">
                  <ProjectCard setProject={setProject} />
                </GridItem>
                <GridItem colSpan={3} rowSpan={3} bg="white">
                  <CardContainer project={project} />
                </GridItem>
                <GridItem colSpan={1} rowSpan={3} bg="white">
                  <Widgets />
                </GridItem>
                <GridItem colSpan={5} rowSpan={1} bg="white">
                  <SponsorCard content={bannerContent}/>
                </GridItem>
              </Grid>
            </TabPanel>
            <TabPanel>
            <Box
                w="100%"
                h="100%"
                borderWidth="2px"
                borderRadius="lg"
                overflow="none"
              >
   
              <About />
              </Box>
            </TabPanel>
            <TabPanel>
              <Box
                w="100%"
                h="100%"
                borderWidth="2px"
                borderRadius="lg"
                overflow="hidden"
              >
                <ContactForm />
              </Box>

            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
    </ChakraProvider>
  );
}

export default App;
