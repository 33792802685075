import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Heading, VStack, Text, CardBody } from '@chakra-ui/react'
import QuestionCard from './QuestionCard';
import baseURL from './Global';

const cardURL = baseURL + "apiv1.php/question/list?project=";
let currentProps = null;

function CardContainer(props) {
    const [post, setPost] = useState(null);
    const [refresh, setRefresh] = useState(false);

    function loadData() {
        currentProps = props;
        setPost(null);
        axios.get(cardURL + props.project.project_id).then((response) => {
            setPost(response.data);
        }).catch((error) => {
            console.log("Error in fetching");
        });
    }


    if (props.project == null) {
        return (
            // empty container as no project has been set
            // can be used to set an initial presentation
            <>
                <Box
                    color="gray.500"
                    fontWeight="semibold"
                    letterSpacing="wide"
                    ml="2"
                    mr="1"
                    pt={2}
                    fontSize="md"
                >

                    <Text>Welcome to the Wigboard</Text><br />
                    <Text>We are interested in knowing what people think about different topics.</Text>
                    <Text>So what do we do? Well, we actually go out on the streets and ask...but in an unusual manner.
                        <br /> One thing that is important to us is to explore different ways of communication.</Text>
                    <br />
                    <Text>Where does the Wigboard name come from? We call ourselves 'wiggers', and if you see one of us you'll understand. If you don't see us, try to find us: we are identifiable with a wig and a board.</Text>
                    <br />
                    <Text>Check out the different projects to learn the different questions and answers we are getting!!</Text>
                </Box>
            </>
        );
    }

    if (currentProps == null) {
        // loading project for the first time
        loadData();
    } else {
        // subsequent changes to project
        if ((currentProps.project.project_id != props.project.project_id)) {
            loadData();
        }
    }

    if (!post) return (
        <>
            <Box p={2}
                w="100%"
                h="100%"
                borderWidth="2px"
                borderRadius="lg"
                overflow="hidden"
            >
                <Heading as='h5' size='sm'>Questions</Heading>
                <p>Loading...</p>
            </Box>
        </>
    );

    return (
        <>

            <Box p={2}
                w="100%"
                h="100%"
                borderWidth="2px"
                borderRadius="lg"
                overflow="hidden"
                
                fontWeight="semibold"
                letterSpacing="wide"
                ml="2"
                pt={2}
            >
                <Box mb={2}>
                    <Heading fontSize='md'>{props.project.name}</Heading>
                    <Text color="gray.500" fontSize='sm'>{props.project.description}</Text>
                </Box>
                {post.map((record, i) => <QuestionCard projectname={props.project.name} data={record} />)}
            </Box>
        </>
    );
};

export default CardContainer;
