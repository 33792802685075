import { Text, Box } from '@chakra-ui/react';
import IframeResizer from 'iframe-resizer-react';

import baseURL from './Global';

const contactURL = baseURL + "contact";

function ContactForm() {
    return (
        <>
        <Box
          color="gray.500"
          fontWeight="semibold"
          letterSpacing="wide"
          ml="2"
          pt={2}
          >
<Text fontSize="md" >Do you have a suggestion for a question or a project?</Text>
<Text fontSize="md" >Do you have something in mind that we could partner with?</Text>
<Text fontSize="md" >Or maybe you want to make a donation?</Text>
<br/>
<Text fontsize="lg" >Whatever the case, please get in touch with us using the form below or by email at <a href="mailto:john.wigmann@gmail.com">john.wigmann@gmail.com</a></Text>
        </Box>
                <Box
          color="gray.500"
          fontWeight="semibold"
          letterSpacing="wide"
          ml="2"
          mb="10"
          pt={2}
          >
 <IframeResizer
    log
    src={contactURL}
    style={{ width: '1px', minWidth: '100%', height:'1000px'}}
    ></IframeResizer>
        </Box>
        </>
    );
};

export default ContactForm;
