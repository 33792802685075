import { Box, VStack, Button, Heading } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import baseURL from './Global';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
} from '@chakra-ui/react'

const projectURL = baseURL + "apiv1.php/project/list?limit=20";

function ProjectCard(props) {
  const [post, setPost] = useState(null);

  function setActiveProject(e) {
    props.setProject(e);
  }

  useEffect(() => {
    axios.get(projectURL).then((response) => {
      setPost(response.data);
    });
  }, []);

  if (!post) return (
    <>
      <Box
        w="100%"
        h="100%"
        borderWidth="2px"
        borderRadius="lg"
        overflow="hidden"
      >
        <Heading as='h5' size='sm'>Projects</Heading>
        <p>Loading...</p>
      </Box>
    </>
  );

  return (
    <>
      <Box
        w="100%"
        h="100%"
        borderWidth="2px"
        borderRadius="lg"
        overflow="hidden"
        p={2}
      >
        <Heading as='h5' size='sm'>Projects</Heading>
        <Box
          color="gray.500"
          fontWeight="semibold"
          letterSpacing="wide"
          fontSize="s"
          textTransform="none"
          pt={2}
        >
          <VStack>
            {post.map(record => { if (record.status == 1) return <Button size='sm' w="100%" onClick={(e) => setActiveProject(record)}> {record.name} </Button> })}
          </VStack>
        </Box>
        <Heading pt={5} as='h5' size='sm'>Coming soon</Heading>
        <Box
          color="gray.500"
          fontWeight="semibold"
          letterSpacing="wide"
          fontSize="s"
          textTransform="none"
          pt={2}
        >
          <VStack>
            {post.map(record => {
              if (record.status == 2) return (
              <Popover>
                <PopoverTrigger>
                  <Button size='sm' w="100%" > {record.name} </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>{record.name}</PopoverHeader>
                  <PopoverBody>This project will be active once it has started and data is available.<br/>Visit us frequently!</PopoverBody>
                </PopoverContent>
              </Popover>)
            })}
          </VStack>
        </Box>
      </Box>
    </>

  );

};

export default ProjectCard;