import { Text, Box, Icon, Badge } from '@chakra-ui/react'
import { FaMapMarkerAlt } from 'react-icons/fa';
import { useState, useEffect } from 'react';

var badges = {
  1: ['LIVE  NOW!', 'red'],
  2: ['NEW', 'blue'],
  3: ['COMING SOON', 'yellow']
};

function QuestionCard(props) {
  const [reveal, setReveal] = useState(false);
//  const [seconds, setSeconds] = useState(0);
  const [blink, setBlink] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
//      setSeconds((prevCounter) => prevCounter + 1);
      setBlink((prevBlink) => !prevBlink);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  function revealAnswer() {
    setReveal(true);
  }

  return (
    <Box mb={1} borderWidth="2px" borderRadius="lg" overflow="hidden">
      <Box p="2">
        <Box display="flex" alignItems="baseline">
          <Badge borderRadius="full" px="2" pt={1} colorScheme="teal">
            {props.projectname}
          </Badge>
          {( ((props.data.badge != null) && (props.data.badge > 1)) ? (<Badge borderRadius="full" px="2" pt={1} colorScheme={badges[props.data.badge][1]}>{badges[props.data.badge][0]}</Badge>) : 
          ( ((props.data.badge == 1) && (blink)) ? (<Badge borderRadius="full" px="2" pt={1} colorScheme={badges[props.data.badge][1]}>{badges[props.data.badge][0]}</Badge>) : (<></>)))
  }
          
        </Box>
        <Box
          mt="1"
          fontWeight="semibold"
          as="h4"
          fontSize="sm"
          lineHeight="tight"
          noOfLines={2}
        >
          {props.data.question}
        </Box>
        <Box
          color="gray.500"
          fontWeight="semibold"
          letterSpacing="wide"
          fontSize="sm"
          textTransform="none"
          ml="2"
          pt={2}
        >
          <p>Date: {props.data.date}</p>
          <p>
            Location: {' '}
            <a href={props.data.location_url} target="_blank">
              {props.data.location} <Icon as={FaMapMarkerAlt} />
            </a>

          </p>
          <p>Method&bull;target: {props.data.method_target}</p>
          <p>Sample: {props.data.sample_size}</p>
          <p>Response:</p>
        </Box>
        <Box
          color="gray.500"
          fontWeight="semibold"
          letterSpacing="wide"
          fontSize="xs"
          textTransform="none"
          ml="2"
          pl={4}
          pt={1}
        >
          {((!reveal) ? <button onClick={revealAnswer}>Click here to reveal!</button> :
            <>
              <p>{(props.data.option1 != null ? props.data.option1 + ' : ' + props.data.count1 : '')}</p>
              <p>{(props.data.option2 != null ? props.data.option2 + ' : ' + props.data.count2 : '')}</p>
              <p>{(props.data.option3 != null ? props.data.option3 + ' : ' + props.data.count3 : '')}</p>
              <p>{(props.data.option4 != null ? props.data.option4 + ' : ' + props.data.count4 : '')}</p>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default QuestionCard;